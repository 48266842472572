/*
? First, let's import the interface that will describe the shape of our property object.
*/

import StateInterface from "../state-interface";

/*
& Now let's describe an initial state for the property object. This is the state that will be used when the app first fires up. Think of it like a placeholder.
*/

const property_data: StateInterface["property"] = {
	_id: "62b2e32e99a2279a2dd33960",
	slug: "vaishnavi-terraces",
	seo: {
		keywords: ["vaishnavi"],
		meta_title: "Luxury Apartments for sale in JP Nagar: Vaishnavi Terraces",
		meta_description:
			"Vaishnavi Terraces by Vaishnavi Group. BDA Approved 3 BHK Luxury Apartments for sale in JP Nagar.",
	},
	search_metrics: {
		search_string: "interiors for vaishnavi terraces",
		search_string_delimiter: "interiors for vaishnavi terraces",
		search_points: 1,
		location_coordinates: [],
		search_filter_hashes: [],
	},
	title: "Vaishnavi Terraces",
	location: {
		title: "",
		pincode: "560076",
		about_location: [
			"Located in one of Bangalore’s most sought after addresses – Dollars Colony, JP Nagar – Vaishnavi Terraces is right there amidst a host of places of everyday importance. From shopping malls, multiplexes, parks and restaurants to banks, hospitals, schools and commercial centers – everything lies within a short distance, giving you an easy commute and endless convenience.",
		],
	},
	description: "customer relationship processes.",
	builder: {
		name: "Vaishnavi Group",
		description:
			"Vaishnavi Group designs homes that resonate with the lifestyle needs of Urban Indians. Their projects are conceived using best in class construction technologies and design thinking. Their commitment to deliver a world-class customer experience reflects in their cutting-edge project delivery and customer relationship processes.",
		year_est: 1995,
		total_projects: 14,
		ongoing_projects: 2,
		completed_projects: 11,
		upcoming_projects: 1,
		logo: {
			url: "https://bucket.beegru.in/vaishnavilogo.webp",
			caption: "Vaishnavi Group Logo",
		},
	},
	price: {
		min: 24500000,
		max: 24500000,
		discounted: {
			min: 24500000,
			max: 24500000,
		},
		currency: "INR",
	},
	price_per_unit: {
		min: {
			ft: 1000,
			mt: 107456,
		},
		max: {
			ft: 1000,
			mt: 107456,
		},
		currency: "INR",
	},
	security_deposit: {
		min: 24500000,
		max: 24500000,
		negotiable: false,
		currency: "INR",
	},
	tags: ["luxury", "apartment", "3bhk", "vaishnavi"],
	area: {
		label: "2455 sqft.",
		enableLabel: false,
		land_area: {
			min: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
			max: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
		},
		super_built_up_area: {
			min: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
			max: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
		},
		carpet_area: {
			min: {
				ft: 1841,
				mt: 171,
				acre: 0.054,
			},
			max: {
				ft: 1841,
				mt: 171,
				acre: 0.054,
			},
		},
		karab_area: {
			min: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
			max: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
		},
	},
	plot_dimensions: ["30x40"],
	other_plot_dimensions: ["30x60"],
	config: {
		parameters: [
			{
				key: "bhk",
				values: ["3.5"],
			},
		],
		label: "3.5 BHK",
	},
	bhk: {
		min: 3.5,
		max: 3.5,
		label: "3.5 BHK",
		enableLabel: false,
	},
	bathroom: {
		min: 2,
		max: 5,
		label: "2 - 5",
		enableLabel: false,
	},
	balconies: {
		min: 1,
		max: 1,
		label: "2 - 5",
		enableLabel: false,
	},
	floors: {
		min: 0,
		max: 5,
		label: "0 - 5",
		enableLabel: false,
	},
	floor_no: {
		min: 0,
		max: 5,
		label: "0 - 5",
		enableLabel: false,
	},
	no_of_seats: {
		min: 0,
		max: 5,
		label: "0 - 5",
		enableLabel: false,
	},
	no_of_units: {
		min: 0,
		max: 5,
		label: "0 - 5",
		enableLabel: false,
	},
	unit_facing: ["north"],
	land_facing: ["north"],
	cover_image: {
		url: "https://bucket.beegru.in/vaishnavi_terrace_cover_image.webp",
		caption: "Vaishnavi Terraces cover image",
	},
	thumbnail_image: {
		url: "https://bucket.beegru.in/vaishnavi_terrace_thumbnail.webp",
		caption: "Vaishnavi Terraces",
	},
	images: [],
	pdf: "https://bucket.beegru.in/pdf/vaishnavi_terrace.pdf",
	status: "untouched",
	assigned_to: [""],
	about_property: ["BDA Approved"],
	amenities: [
		{
			id: "swimming_pool",
			label: "Swimming Pool",
			icon: "amenities/swimming",
		},
	],
	approvals: [
		{
			id: "oc_recieved",
			label: "OC Recieved",
			icon: "approvals/oc",
		},
	],
	vicinity: [
		{
			category: "schools",
			values: [
				{
					title: "Vibgyor High School",
					distance: 1.8,
				},
			],
		},
	],
	gmaps_embed_url:
		"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15556.302663905928!2d77.598268!3d12.902856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15199fc6e967%3A0x8bb3e0c24a6df98b!2sVaishnavi%20Terrace%2C%20Dollars%20Colony%2C%20Phase%204%2C%20J.%20P.%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560076!5e0!3m2!1sen!2sin!4v1608711353602!5m2!1sen!2sin",
	views: [],
	property_subtype: "SEZ",
	property_type: [],
	property_category: "",
	other_property_category: "",
	transaction_type: "",
	availability: ["ready_to_move"],
	furnished: ["unfurnished"],
	possession_date: "2022-06-30T18:30:00.000Z",
	property_age: 0,
	related_services: [],
	show_contact_details: true,
	created_by: {
		is_business_profile: true,
		user_id: "hjasgfafgsafgh",
		business_profile_id: "hsbcdsgcdgshjch",
	},
	listedBy: "",
	flags: [],
	linked: true,
	linked_to: "62b2e32e99a2279a2dd33960",
	allow_copy: true,
	boost_information: {
		active: false,
		starts_at: "2100-01-01T00:00:00.000Z",
		ends_at: "2100-01-01T00:00:00.000Z",
		boost_id: "62b2e32e99a2279a2dd33960",
		total_amount: 0,
	},
	published: false,
	saved_content: [],
	is_public: false,
	is_ofb: true,
	deleted: false,
	deleted_at: "2022-06-30T18:30:00.000Z",
	created_at: "2022-06-30T18:30:00.000Z",
	updated_at: "2022-06-30T18:30:00.000Z",
	use_data_for_fine_tuning: false,
	manual_overview: "",
	simulated: false,
	is_overview_ai_generated: false,
	show_in_featured: false,
	employee_id: "",
	test: false,
	status_history: [],
	ad_text: "",
};

export const property = property_data;
export default property_data;
