import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _j_bathrooms_variant_2: SearchFilter = {
	id: "bathrooms_variant_2",
	title: "Bathrooms",
	level: 3,
	index: 9,
	presentation_type: "checkboxes",
	multi: true,
	affects: [],
	affected_by: ["property_type"],
	priority: 3,
	is_advanced: false,
	included_in_search_bar: false,
	option_type: "text",
	possible_options: [
		{
			id: "1_bathroom",
			title: "1 Bathroom",
			value: "1",
		},
		{
			id: "2_bathroom",
			title: "2 Bathrooms",
			value: "2",
		},
		{
			id: "3_bathroom",
			title: "3 Bathrooms",
			value: "3",
		},
		{
			id: "4_bathroom",
			title: "4 Bathrooms",
			value: "4",
		},
		{
			id: "5_bathroom",
			title: "5+ Bathrooms",
			value: "5",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "1_bathroom",
			title: "1 Bathroom",
			value: "1",
			is_selected: false,
		},
		{
			id: "2_bathroom",
			title: "2 Bathrooms",
			value: "2",
			is_selected: false,
		},
		{
			id: "3_bathroom",
			title: "3 Bathrooms",
			value: "3",
			is_selected: false,
		},
		{
			id: "4_bathroom",
			title: "4 Bathrooms",
			value: "4",
			is_selected: false,
		},
		{
			id: "5_bathroom",
			title: "5+ Bathrooms",
			value: "5",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "bathroom",
	is_visible: false,
	is_applied: false,
	is_accordion_open: true,
};
