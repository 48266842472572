/*
? First, let's import the interface that will describe the shape of our property object.
*/

import StateInterface from "../state-interface";

/*
& Now let's describe an initial state for the property object. This is the state that will be used when the app first fires up. Think of it like a placeholder.
*/

const property_data: StateInterface["property"] = {
	_id: "",
	slug: "",
	seo: {
		keywords: [],
		meta_title: "",
		meta_description: "",
	},
	search_metrics: {
		search_string: "",
		search_string_delimiter: "",
		search_points: 1,
		location_coordinates: [],
		search_filter_hashes: [],
	},
	title: "",
	location: {
		title: "",
		pincode: "",
		about_location: [""],
	},
	description: "",
	builder: {
		name: "Vaishnavi Group",
		description: "",
		year_est: 1995,
		total_projects: 14,
		ongoing_projects: 2,
		completed_projects: 11,
		upcoming_projects: 1,
		logo: {
			url: "",
			caption: "",
		},
	},
	price: {
		min: 0,
		max: 0,
		discounted: {
			min: 24500000,
			max: 24500000,
		},
		currency: "",
	},
	price_per_unit: {
		min: {
			ft: 1000,
			mt: 107456,
		},
		max: {
			ft: 1000,
			mt: 107456,
		},
		currency: "",
	},
	security_deposit: {
		min: 0,
		max: 0,
		negotiable: false,
		currency: "",
	},
	tags: [],
	area: {
		label: "",
		enableLabel: false,
		land_area: {
			min: {
				ft: 0,
				mt: 0,
				acre: 0,
			},
			max: {
				ft: 0,
				mt: 0,
				acre: 0,
			},
		},
		super_built_up_area: {
			min: {
				ft: 0,
				mt: 0,
				acre: 0,
			},
			max: {
				ft: 0,
				mt: 0,
				acre: 0,
			},
		},
		carpet_area: {
			min: {
				ft: 0,
				mt: 0,
				acre: 0,
			},
			max: {
				ft: 0,
				mt: 0,
				acre: 0,
			},
		},
		karab_area: {
			min: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
			max: {
				ft: 2455,
				mt: 228,
				acre: 0.054,
			},
		},
	},
	plot_dimensions: [""],
	other_plot_dimensions: [""],
	config: {
		parameters: [
			{
				key: "",
				values: [""],
			},
		],
		label: "3.5 BHK",
	},
	bhk: {
		min: 0,
		max: 0,
		label: "",
		enableLabel: false,
	},
	bathroom: {
		min: 2,
		max: 5,
		label: "",
		enableLabel: false,
	},
	balconies: {
		min: 1,
		max: 1,
		label: "",
		enableLabel: false,
	},
	floors: {
		min: 0,
		max: 5,
		label: "",
		enableLabel: false,
	},
	floor_no: {
		min: 0,
		max: 5,
		label: "",
		enableLabel: false,
	},
	no_of_seats: {
		min: 0,
		max: 0,
		label: "",
		enableLabel: false,
	},
	no_of_units: {
		min: 0,
		max: 0,
		label: "",
		enableLabel: false,
	},
	unit_facing: [""],
	land_facing: [""],
	cover_image: {
		url: "",
		caption: "",
	},
	thumbnail_image: {
		url: "",
		caption: "",
	},
	images: [],
	pdf: "",
	status: "untouched",
	assigned_to: [""],
	about_property: [" "],
	amenities: [
		{
			id: "",
			label: " ",
			icon: "",
		},
	],
	approvals: [
		{
			id: "",
			label: "",
			icon: "",
		},
	],
	vicinity: [
		{
			category: "",
			values: [
				{
					title: "",
					distance: 1.8,
				},
			],
		},
	],
	gmaps_embed_url: "",
	views: [],
	property_subtype: "",
	property_type: [],
	property_category: "",
	other_property_category: "",
	transaction_type: "",
	availability: [],
	furnished: [],
	possession_date: "",
	property_age: 0,
	related_services: [],
	show_contact_details: true,
	created_by: {
		is_business_profile: true,
		user_id: "",
		business_profile_id: "",
	},
	listedBy: "",
	linked: false,
	linked_to: "",
	allow_copy: false,
	boost_information: {
		active: false,
		starts_at: "2100-01-01T00:00:00.000Z",
		ends_at: "2100-01-01T00:00:00.000Z",
		boost_id: "",
		total_amount: 0,
	},
	flags: [],
	published: false,
	saved_content: [],
	test: false,
	status_history: [],
	is_public: false,
	is_ofb: true,
	deleted: false,
	deleted_at: "",
	created_at: "",
	updated_at: "",
	use_data_for_fine_tuning: false,
	show_in_featured: false,
	manual_overview: "",
	simulated: false,
	is_overview_ai_generated: false,
	employee_id: "",
	ad_text: "",
};

export const property = property_data;
export default property_data;
