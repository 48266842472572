import { SearchFiltersConfigType } from "../../../redux-magic/sub-interfaces/search-types";

export const SearchFiltersConfig: SearchFiltersConfigType = {
	location: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	search_type: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	transaction_type: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	property_category: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	property_type: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	budget: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	area: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	property_status: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	floors: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	furnishing: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	amenities: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	unit_facing: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	listed_by: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	seats: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	land_facing: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	plot_dimensions: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	bedrooms: {
		enabled: false,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	bedrooms_variant_2: {
		enabled: true,
		variant: 2,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	bathrooms: {
		enabled: false,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	bathrooms_variant_2: {
		enabled: true,
		variant: 2,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	balconies: {
		enabled: false,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	balconies_variant_2: {
		enabled: true,
		variant: 2,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	units: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	security_deposit: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	available_from: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	possession_date: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	service_category: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	service_type: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	service_subtype: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
	business_profile_type: {
		enabled: true,
		variant: 1,
		last_modified: "2025-03-25T00:00:00.000Z",
	},
};
