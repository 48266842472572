/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const saved_items: StateInterface["saved_items"] = {
	_id: "",
	saved_item: {
		_id_copy: "",
		saved_by: {
			_id: "",
			is_business_profile: false,
			user_id: "",
			business_profile_id: "",
		},
		entity_type: "",
		entity_id: "",
		entity_created_by: {
			is_business_profile: false,
			user_id: "",
			business_profile_id: "",
		},
	},
	saved_at: "",
};
