import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _k_balconies_variant_2: SearchFilter = {
	id: "balconies_variant_2",
	title: "Balconies",
	level: 3,
	index: 10,
	presentation_type: "checkboxes",
	multi: true,
	affects: [],
	affected_by: ["property_type"],
	priority: 3,
	is_advanced: false,
	included_in_search_bar: false,
	option_type: "text",
	possible_options: [
		{
			id: "1_balcony",
			title: "1 Balcony",
			value: "1",
		},
		{
			id: "2_balcony",
			title: "2 Balconies",
			value: "2",
		},
		{
			id: "3_balcony",
			title: "3 Balconies",
			value: "3",
		},
		{
			id: "4_balcony",
			title: "4 Balconies",
			value: "4",
		},
		{
			id: "5_balcony",
			title: "5+ Balconies",
			value: "5",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "1_balcony",
			title: "1 Balcony",
			value: "1",
			is_selected: false,
		},
		{
			id: "2_balcony",
			title: "2 Balconies",
			value: "2",
			is_selected: false,
		},
		{
			id: "3_balcony",
			title: "3 Balconies",
			value: "3",
			is_selected: false,
		},
		{
			id: "4_balcony",
			title: "4 Balconies",
			value: "4",
			is_selected: false,
		},
		{
			id: "5_balcony",
			title: "5+ Balconies",
			value: "5",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "balconies",
	is_visible: false,
	is_applied: false,
	is_accordion_open: true,
};
