import StateInterface from "../state-interface";

export const cta_leads: StateInterface["cta_leads"] = {
	_id: "",
	name: "John Doe",
	phone: {
		phone_number: "08012345678",
		country_code: "234",
	},
	agreed_to_tc: true,
	source: "website",
	status: "",
	notes: "",
	created_at: "2020-11-01T12:00:00.000Z",
	listing_slug: "5f9f5b5b9b9b9b9b9b9b9b9b",
	listing_type: "product",
	listing_id: "",
	is_guest: false,
	listing_title: "",
	listing_owner: {
		is_business_profile: false,
		user_id: "",
		business_profile_id: "",
	},
	user_id: "",
	enquiry_count: 0,
	last_enquiry_at: "",
	is_lead_from_boost: false,
};
