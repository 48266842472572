/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const shares: StateInterface["shares"] = {
	_id: "",
	shared_item: {
		_id_copy: "",
		shared_by: {
			_id: "",
			is_business_profile: false,
			user_id: "",
			business_profile_id: "",
			is_guest: false,
		},
		shared_through: "",
		entity_type: "",
		entity_id: "",
		entity_created_by: {
			is_business_profile: false,
			user_id: "",
			business_profile_id: "",
		},
	},
	created_at: "",
};
