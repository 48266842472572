/* State Imports */

import StateInterface from "../state-interface";
import { user } from "./user";

/* Single user Profile Page Default State Data */

export const single_user_profile: StateInterface["single_user_profile"] = {
	user_profile: user,
	posts: [],
	postsCount: 0,
	reviews: [],
	reviewsCount: 0,
	given_reviews: [],
	given_reviews_count: 0,
	// boosts: [],
	// claims: [],
	// contributions: [],
	ofb_properties: [],
	propertiesCount: 0,
	searched_properties: [],
	// boosted_entity_data: [],
	// pages: [],
	saved_items: {
		properties: [],
		services: [],
		posts: [],
		ofb: [],
	},
	search_saved_items_response: {
		saved_items_ids: [],
		trigger_new_search: false,
		saved_items_loading: true,
		initial_load_done: false,
		saved_items_count: 0,
		filter_listing_type: "property",
		filter_page_number: 1,
	},
	user_profile_loading: true,
	analytics: {
		post_booms_per_day: [],
		post_blasts_per_day: [],
		post_views_per_day: [],
		property_views_per_day: [],
		ofb_property_views_per_day: [],
		service_views_per_day: [],
	},
	savedPropertiesLength: 0,
	savedServicesLength: 0,
	savedPostsLength: 0,
	savedOFBLength: 0,
	leads: [],
};
